import type { CartConfig } from '#types/config/components/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    header: 'mb-2 b-grey-80',
    noItems: 'py-10 md:mt-5 text-sm fw-normal',
    itemsPerShipping: 'b-grey-80'
  },
  coupon: {
    brandClasses: {
      wrapper: 'b-none',
      formError: 'mt-3',
      pillsWrapper: 'flex wrap mt-3 gap-3',
      pillsTitle: 'text-base'
    }
  },
  disablePdpLinks: true,
  item: {
    actionMenu: {
      brandClasses: {
        button: 'px-4 py-5 gap-3',
      }
    }
  },
  outOfStock: {
    showHeader: false,
    showPrice: false,
    showRemoveAll: false,
    showRemoveItem: false,
    showSaveForLater: false
  },
  quickshop: {
    wrapper: {
      lg: 'panel'
    },
    position: 'right'
  },
  stickyCtas: {
    showBottomStickyCheckoutCta: true,
  },
  showItemLevelPromocode: false,
  showOrderLevelPromocodeOnItem: false,
  thumbnails: {
    size: { width: 96, height: 120 }
  },
  cartItem: {
    thumbnails: {
      width: { sm: 94, lg: 128 },
      height: { sm: 118, lg: 160 }
    }
  },
} satisfies RecursivePartial<CartConfig>
