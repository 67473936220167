import type { PagesConfig } from '#types/config/pages'
import type { RecursivePartial } from '#types/utils'

export default {
  showLoginForm: false,
  brandClasses: {
    formsWrapper: 'flex center',
    trackOrderForm: 'md:w-1/2 lg:w-2/5',
    heading: 'title-4'
  },
  orderStatusHelpText: {
    requiredLocales: []
  }
} satisfies RecursivePartial<PagesConfig['orderStatus']>
