import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    box: 'text-md w-full',
    unavailable: 'bg-slash-hollow bg-grey-80 b-grey-40 hover:b-grey-10 c-grey-20',
    unavailableSelected: 'bg-slash-hollow bg-grey-80 b-grey-10 c-grey-10',
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
