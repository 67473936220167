import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rectangle: {
      cross: 'bg-white/60 bg-slash',
      swatch: '',
      swatchActive: 'b b-black'
    }
  },
  brandStyles: {
    rectangle: {
      container: {
        'aspect-ratio': {
          sm: '0.797872',
          md: '0.797872',
          lg: '0.797872'
        },
        'h': {
          sm: '',
          md: '',
          lg: ''
        },
        'p': {
          sm: '0',
          md: '0',
          lg: '0'
        },
        'w': {
          sm: '4.1875rem',
          md: '5.28125rem',
          lg: '5.34375rem'
        }
      },
      cross: {
        inset: {
          sm: '0',
          md: '0',
          lg: '0'
        }
      },
      swatch: {
        inset: {
          sm: '0',
          md: '0',
          lg: '0'
        }
      },
      swatchUnavailable: {
        inset: {
          sm: '0',
          md: '0',
          lg: '0'
        }
      }
    }
  },
  config: {
    thumbnail: {
      rectangle: {
        height: 188,
        width: 150
      }
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
