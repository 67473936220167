import type { RecursivePartial } from '#types/utils'
import type { ProductListConfig } from '#types/config/components/product/list'

export default {
  brandClasses: {
    noResults: 'mb-14 mt-12 text-center fw-medium lg:mt-10 title-2',
    outerGrid: 'pt-6 container <md:px-0'
  },
  grid: {
    gaps: {
      x: { sm: 'xs' },
      y: { sm: 'lg', md: 'xl' }
    }
  }
} satisfies RecursivePartial<ProductListConfig>
