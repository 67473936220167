import type { SignUpConfig } from '#types/config/components/form/signUp'

export default {
  agreementCheckboxes: [
    {
      type: 'loyaltyTerms',
      locales: ['en-us'],
      required: true
    },
    {
      type: 'policy',
      locales: ['en-us'],
      required: true
    },
    {
      type: 'newsletter'
    }
  ],
  brandClasses: {
    checkboxesWrapper: 'space-y-4',
    heading: 'text-lg fw-medium',
    helpText: 'my-4 text-sm c-grey-20',
    passwordRequirements: '',
    submitButton: 'w-full'
  },
  isLoyaltyNewsletterAllowed: false,
  minimalAge: 16,
  mobilePhone: {
    requiredLocales: [
      'en-us',
      'en-ca',
      'fr-ca'
    ]
  },
  onSuccessNavigateTo: '/account',
  showBirthDateField: true,
} satisfies SignUpConfig
